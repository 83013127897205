<template>
  <svg
    fill="none"
    height="22"
    viewBox="0 0 16 22"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4851 8.24903L14.4313 9.3362L8.70303 3.608L8.70303 21.2695L7.19026 21.2695L7.19026 3.608L1.44153 9.3567L0.387695 8.26957L7.94666 0.71063L15.4851 8.24903Z"
      fill="white"
    />
  </svg>
</template>
