<template>
  <nav
    ref="dropdownWrapper"
    :class="{ 'dropdown__wrapper--active': isDropdownActive }"
    class="dropdown__wrapper"
    @click="handleLabelClick"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div class="wrapper__label">
      <slot name="label" />

      <icon-chevron v-if="withChevron" class="label__icon" />
    </div>

    <transition>
      <div v-if="isDropdownActive" class="wrapper__card-container">
        <card-wrapper class="card-container__card" gradient-border shadow>
          <slot />
        </card-wrapper>
      </div>
    </transition>
  </nav>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, type Ref } from "vue";
import CardWrapper from "~/modules/shared/components/CardWrapper.vue";
import IconChevron from "~/modules/shared/components/icons/IconChevron.vue";
import { useIsMobileStore } from "~/modules/shared/stores/isMobile";

withDefaults(
  defineProps<{
    withChevron?: boolean;
  }>(),
  {
    withChevron: false,
  },
);

const { isMobile } = useIsMobileStore();
const dropdownWrapper: Ref<HTMLElement | undefined> = ref();
const isDropdownActive = ref(false);

const handleLabelClick = () => {
  if (!isMobile) return;

  isDropdownActive.value = true;
  window.addEventListener("click", handleClick);
};

const handleClick = (event: Event) => {
  if (!dropdownWrapper.value || !isMobile) return;

  if (
    event.target !== dropdownWrapper.value &&
    !event.composedPath().includes(dropdownWrapper.value)
  ) {
    isDropdownActive.value = false;
    window.removeEventListener("click", handleClick);
  }
};

const handleMouseEnter = () => !isMobile && (isDropdownActive.value = true);
const handleMouseLeave = () => !isMobile && (isDropdownActive.value = false);

onBeforeUnmount(() => {
  window.removeEventListener("click", handleClick);
});
</script>

<style lang="scss" scoped>
.dropdown__wrapper {
  @apply relative flex items-center py-4;

  .wrapper__label {
    @apply w-full flex items-center md:justify-center cursor-pointer text-body-xxs;

    .label__icon {
      @apply ml-2;
    }
  }

  .wrapper__card-container {
    @apply absolute top-14 left-1/2 z-20;
    transform: translateX(-50%);
    transition: 0.4s;

    .card-container__card {
      @apply px-2 py-3 min-w-32;
    }
  }
}
</style>
