<template>
  <div class="call-to-action__container">
    <section-wrapper>
      <template #title>
        {{ $t("getInTouch") }}
      </template>

      <div>
        <app-button
          v-if="contactPage"
          :url="localePath(`/${contactPage.attributes.slug}`)"
          class="call-to-action__button"
          variant="primary"
        >
          {{ $t("contactUs") }}
        </app-button>

        <div class="cta-image__container">
          <img
            alt="Call to action image"
            class="container__image"
            src="/cta_image.png"
          />
        </div>
      </div>

      <template #additional-post>
        <div class="section__bg" />
      </template>
    </section-wrapper>
  </div>
</template>

<script lang="ts" setup>
import AppButton from "~/modules/shared/components/AppButton.vue";
import SectionWrapper from "~/modules/shared/components/SectionWrapper.vue";
import { useGlobalStore } from "~/modules/shared/stores/global";

const localePath = useLocalePath();
const globalStore = useGlobalStore();
const { contactPage } = storeToRefs(globalStore);
</script>

<style lang="scss" scoped>
.call-to-action__container {
  @apply pt-56 overflow-hidden;
  border-bottom: 1px solid theme("colors.primary-100");

  :deep(.section-inner__container) {
    @apply relative py-48 z-10;
  }

  :deep(.section__header) {
    @apply md:w-1/3 xl:w-1/4 mb-2;
  }

  :deep(.header__title) {
    @apply text-left text-title;
  }

  .call-to-action__button {
    @apply w-full md:w-auto;
  }

  .section__bg {
    @apply absolute top-0 left-0 w-full h-full;
    background: rgba(255, 255, 255, 0.3)
      url("/assets/images/white_signet_rot.png");
    background-repeat: space;
    background-size: 5rem;
    opacity: 0.03;
  }

  .cta-image__container {
    @apply absolute bottom-0 right-36 hidden md:block md:w-1/2 xl:w-auto;

    &::before {
      content: "";
      @apply absolute;
      top: -25%;
      left: -36%;
      width: 100rem;
      aspect-ratio: 1 / 1;
      background: radial-gradient(rgba(#000000, 80%), rgba(#000000, 0) 65%);

      @screen xl {
        width: 140rem;
      }
    }

    .container__image {
      @apply relative z-10;
    }
  }
}
</style>
