<template>
  <span class="icon icon-chevron" />
</template>

<style lang="scss" scoped>
.icon-chevron {
  width: 0;
  height: 0;
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  border-top: 0.3rem solid white;
  transition: 0.4s;
}
</style>
