<template>
  <nav class="primary-menu__container">
    <slot name="pre"></slot>

    <ul v-if="data?.attributes.items.length" class="items__list">
      <li
        v-for="item in data.attributes.items"
        :key="`primary-footer-menu-item-${item.id}`"
        class="list__item"
      >
        <nuxt-link
          :to="localePath(item.url || `/${item.page.data?.attributes.slug}`)"
          class="item__link gradient-link"
        >
          {{ item.title }}
        </nuxt-link>
      </li>
    </ul>

    <slot name="post">
      <button class="button" @click="handleScrollToTop">
        <icon-arrow class="icon" />

        {{ $t("backToTheTop") }}
      </button>
    </slot>
  </nav>
</template>

<script lang="ts" setup>
import IconArrow from "~/modules/shared/components/icons/IconArrow.vue";
import type { Menu } from "~/modules/shared/ts/Menu";

defineProps<{
  data?: Menu | null;
}>();

const localePath = useLocalePath();

const handleScrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
</script>

<style lang="scss" scoped>
.primary-menu__container {
  @apply flex flex-col md:flex-row items-center justify-between pb-14 text-body-xxs;

  .button {
    @apply flex items-center mt-20 md:mt-0;

    .icon {
      @apply mr-4 md:mr-10;
    }
  }

  .items__list {
    @apply flex flex-col md:flex-row mt-14 md:mt-0 w-full md:w-auto;

    .list__item {
      @apply mb-6 md:mb-0 md:ml-16 text-center;

      &:first-child {
        @apply ml-0;
      }
    }

    .item__link {
      @apply inline-block py-2 md:py-0 px-10 md:px-0;
    }
  }
}
</style>
