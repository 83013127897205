<template>
  <dropdown-wrapper class="user-menu__container">
    <template #label>
      <figure class="user__figure">
        <nuxt-picture
          :img-attrs="{ class: 'figure__picture' }"
          :src="userPictureSrc"
          class="figure__picture"
        />
      </figure>

      <div class="user-details__container">
        <p class="details__name gradient-text">
          {{ user.full_name }}
        </p>

        <p class="details__email">
          {{ user.email }}
        </p>
      </div>
    </template>

    <ul class="items__list">
      <li
        v-for="item in dashboardNavigationItems"
        :key="`user-navigation-item-${item.route}`"
        class="list__item"
      >
        <nuxt-link :to="localePath(item.route)" class="item__link">
          <component :is="item.icon" class="link__icon" />

          <div class="link__details">
            <p class="details__title">{{ $t(item.label) }}</p>
            <p class="details__description">{{ $t(item.description) }}</p>
          </div>
        </nuxt-link>
      </li>

      <li class="list__item list__item--membership">
        <p
          v-if="userSubscription?.attributes.subscription_tier?.data"
          class="gradient-text membership__name"
        >
          {{
            userSubscription?.attributes.subscription_tier?.data.attributes.name
          }}
        </p>

        <p>
          {{ $t("status") }}:

          <span
            :class="{ 'status__value--active': isUserSubscriptionActive }"
            class="status__value"
          >
            {{
              isUserSubscriptionActive
                ? $t("statusActive")
                : $t("statusNotActive")
            }}
          </span>
        </p>
      </li>

      <li class="list__item">
        <button
          class="item__link text-error-500"
          @click="handleLogoutButtonClick"
        >
          <icon-sign-out class="button__icon" />

          {{ $t("signOut") }}
        </button>
      </li>
    </ul>
  </dropdown-wrapper>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { dashboardNavigationItems } from "~/modules/auth/constants";
import { useUserSubscriptionStore } from "~/modules/auth/stores/userSubscription";
import type { User } from "~/modules/auth/ts/User.type";
import DropdownWrapper from "~/modules/shared/components/DropdownWrapper.vue";
import IconSignOut from "~/modules/shared/components/icons/IconSignOut.vue";

const props = defineProps<{
  user: User;
}>();

const localePath = useLocalePath();

const { logout } = useStrapiAuth();
const router = useRouter();

const userSubscriptionStore = useUserSubscriptionStore();
const { isUserSubscriptionActive, userSubscription } = storeToRefs(
  userSubscriptionStore,
);
const userPictureSrc = computed(() => {
  return props.user.avatar?.url || "/user.png";
});

const handleLogoutButtonClick = () => {
  logout();
  router.push(localePath("/"));
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.user-menu__container {
  @apply mr-10;

  :deep(.wrapper__card-container) {
    @apply top-auto bottom-28 md:top-24 md:bottom-auto;
  }
}

.user__figure {
  @apply w-16 h-16;

  .figure__picture {
    @apply block rounded-full overflow-hidden;

    :deep {
      .figure__picture {
        @apply w-full h-full;
        object-fit: cover;
      }
    }
  }
}

.user-details__container {
  @apply ml-6;

  .details__name {
    @apply mb-3 text-body-xxs;
  }

  .details__email {
    font-size: 1.2rem;
  }
}

.items__list {
  @apply m-4;
  width: 30.7rem;

  .list__item {
    &--membership {
      @apply px-6 py-8 my-6 border-primary-100;
      border-top-width: 1px;
      border-bottom-width: 1px;

      .membership__name {
        @apply mb-3;
      }

      .status__value {
        @apply text-error-500;

        &--active {
          @apply text-success-500;
        }
      }
    }
  }

  .item__link {
    @apply relative flex items-center w-full p-5 mb-2;

    &::before {
      @apply absolute w-full h-full top-0 left-0 rounded-2xl opacity-0;
      content: "";
      @include gradient;
      transition: 0.4s;
    }

    &:hover {
      &::before {
        @apply opacity-10;
      }

      .link__icon {
        filter: brightness(1);
      }
    }

    &.router-link-active {
      .link__icon {
        filter: brightness(1);
      }
    }

    .link__icon {
      filter: brightness(10);
      transition: 0.4s;
    }

    .button__icon {
      @apply mr-4;
    }

    .link__details {
      @apply ml-6;
    }

    .details__title {
      @apply mb-2 text-body-xxs;
    }

    .details__description {
      @apply text-body-xxxs text-primary-400;
    }
  }
}
</style>
