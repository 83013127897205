<template>
  <div class="child-menu__container">
    <card-wrapper class="child-menu__card" shadow>
      <ul class="card__items-list">
        <li
          v-for="item in items"
          :key="`header-menu-${item.id}-child-item-${item.id}`"
          class="list__item"
        >
          <nuxt-link
            :to="localePath(item.url || `/${item.page.data?.attributes.slug}`)"
            class="gradient-link"
          >
            <span v-if="item.icon" class="material-symbols-outlined link__icon">
              {{ item.icon }}
            </span>

            <div>
              <p class="link__title">{{ item.title }}</p>

              <p class="link__subtitle">{{ item.subtitle }}</p>
            </div>
          </nuxt-link>
        </li>
      </ul>
    </card-wrapper>
  </div>
</template>

<script lang="ts" setup>
import CardWrapper from "~/modules/shared/components/CardWrapper.vue";
import type { MenuItem } from "~/modules/shared/ts/Menu";

defineProps<{
  items: MenuItem[];
  parentId: number;
}>();

const localePath = useLocalePath();
</script>

<style lang="scss" scoped>
@import "~/assets/styles/mixins";
@import "~/assets/styles/global";

.child-menu__container {
  @apply w-full;

  @screen md {
    @apply absolute top-14 left-1/2 z-20;
    width: 28rem;
    transform: translateX(-50%);
  }

  .child-menu__card {
    @apply w-full p-6 bg-primary-75 border-0;

    @media screen and (max-width: theme("screens.md")) {
      @apply border-0;

      &::before {
        content: none;
      }
    }
  }

  .gradient-link {
    @apply relative flex items-center p-5 text-left;

    &::before {
      @apply absolute w-full h-full top-0 left-0 opacity-0 rounded-xl;
      @include gradient();
      content: "";
      transition: 0.4s;
    }

    &:hover {
      &::before {
        @apply opacity-10;
      }
    }

    &:hover,
    &.router-link-exact-active {
      .link__icon {
        filter: brightness(1);
      }
    }

    .link__icon {
      @apply w-10 mr-6;
      @extend .gradient-text;
      transition: 0.4s;
      filter: brightness(10);
    }

    .link__subtitle {
      @apply mt-2 text-body-xxxs text-primary-400;
    }
  }
}
</style>
