<template>
  <header class="header__container">
    <div class="header__inner-container">
      <nuxt-link :to="localePath('/')" class="logo mobile-logo">
        <figure class="logo__figure">
          <nuxt-picture alt="AFV logo" src="/logo.svg" />
        </figure>
      </nuxt-link>

      <div
        :class="{ 'navigation__container--active': mobileMenuActive }"
        class="navigation__container"
      >
        <div class="header__col header__col--lg">
          <nuxt-link :to="localePath('/')" class="logo">
            <figure class="logo__figure">
              <nuxt-picture alt="AFV logo" src="/logo.svg" />
            </figure>
          </nuxt-link>

          <header-navigation class="header__nav" />
        </div>

        <div class="header__col header__col--sm">
          <template v-if="user?.id">
            <user-dropdown :user="user" />
          </template>

          <template v-else>
            <app-button
              :url="localePath('/waitlist')"
              class="button"
              variant="primary"
            >
              {{ $t("waitlist.joinWaitlist") }}
            </app-button>
          </template>

          <language-selector class="lang-selector__container" />
        </div>
      </div>

      <button
        :class="{ 'mobile-nav__button--active': mobileMenuActive }"
        class="mobile-nav__button"
        @click="handleMobileButtonClick"
      >
        <span />
      </button>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { ref, useLocalePath, useStrapiUser } from "#imports";
import UserDropdown from "~/modules/auth/components/userDropdown/UserDropdown.vue";
import type { User } from "~/modules/auth/ts/User.type";
import AppButton from "~/modules/shared/components/AppButton.vue";
import HeaderNavigation from "~/modules/shared/containers/header/HeaderNavigation.vue";
import LanguageSelector from "~/modules/shared/containers/header/LanguageSelector.vue";

const localePath = useLocalePath();
const user = useStrapiUser<User>();

const mobileMenuActive = ref(false);

const handleMobileButtonClick = () =>
  (mobileMenuActive.value = !mobileMenuActive.value);
</script>

<style lang="scss" scoped>
.header__container {
  @apply fixed top-0 left-0 w-full mb-12 bg-primary-50 z-20;

  .header__inner-container {
    @apply max-w-custom flex items-center px-8 md:px-16 lg:px-36 py-7 mx-auto;
    border-bottom: 1px solid #3c3939;
  }

  .navigation__container {
    @apply w-full flex z-30;

    @media screen and (max-width: theme("screens.md")) {
      @apply fixed h-screen top-0 left-0 flex-col p-10 pt-14 bg-primary-50 overflow-y-auto;
      transform: translateX(100%);
      transition: 0.4s;

      &--active {
        transform: translateX(0);
      }
    }

    @screen md {
      @apply static h-auto items-center flex-col xl:flex-row justify-between p-0;
      transform: translateX(0);
      transition: 0s;
    }
  }

  .logo {
    @apply w-44 xl:w-56 2xl:w-72 mr-8 xl:mr-20 2xl:mr-24;

    .logo__figure {
      @apply w-full;

      :deep {
        img {
          @apply w-full;
          object-fit: contain;
        }
      }
    }
  }

  .mobile-logo {
    @apply md:hidden;
  }

  .header__nav {
    @apply md:ml-auto xl:ml-0;

    @media screen and (max-width: theme("screens.md")) {
      @apply mt-20;
    }
  }

  .header__col {
    @apply w-full xl:w-auto flex flex-col md:flex-row md:items-center text-body-xs md:text-body-xxs;

    &--lg {
      @apply xl:flex-grow mb-10 xl:mb-0;
    }

    &--sm {
      @apply flex flex-col md:justify-end ml-auto mt-auto md:mb-10 border-t-2 border-primary-100;

      @screen md {
        @apply w-auto flex-row mt-0 md:mb-0 md:border-t-0;
      }
    }

    .link {
      @apply py-8 md:py-2 md:mr-10 mb-4 md:mb-0 text-center;
    }

    .button {
      @apply px-6 xl:px-10 2xl:px-14 md:mr-10 mb-4 md:mb-0 text-body-xs md:text-body-xxs;
    }
  }

  .lang-selector__container {
    @media screen and (max-width: theme("screens.md")) {
      @apply absolute top-10 right-32;
    }
  }

  .mobile-nav__button {
    $width: 3rem;
    @apply md:hidden h-16 ml-auto z-40;

    span,
    &::before,
    &::after {
      @apply block;
      width: $width;
      height: 2px;
      background: white;
      transition: 0.4s;
      transform-origin: 1.5rem 0;
    }

    &::before,
    &::after {
      content: "";
    }

    span {
      @apply my-2;
    }

    &--active {
      @apply z-50;

      span {
        @apply opacity-0 my-0;
      }

      &::before {
        transform: rotate(45deg) translateX(0.4rem);
      }

      &::after {
        transform: rotate(-45deg) translateX(0.2rem);
      }
    }
  }
}
</style>
