<template>
  <nav v-if="headerMenu?.id" class="header__nav">
    <ul class="items__list">
      <li
        v-for="item in menuItems"
        :key="`header-menu-item-${item.id}`"
        class="list__item"
      >
        <nuxt-link
          v-if="item.url"
          :to="localePath(item.url)"
          class="gradient-link"
        >
          {{ item.title }}
        </nuxt-link>

        <p v-else class="gradient-link">
          {{ item.title }}
        </p>

        <template v-if="item.childMenu.data?.attributes.items.length">
          <button
            :class="{
              'mobile-child-menu-button--active':
                activeMobileChildMenu === item.id,
            }"
            class="mobile-child-menu-button"
            @click="handleMobileChildMenuButtonClick(item.id)"
          >
            <icon-chevron class="icon" />
          </button>

          <child-menu
            :class="{
              'child-menu__container--active':
                activeMobileChildMenu === item.id,
            }"
            :items="item.childMenu.data.attributes.items"
            :parent-id="item.id"
            class="child-menu__container"
          />
        </template>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";

import type { ComputedRef, Ref } from "vue";
import ChildMenu from "~/modules/shared/components/headerNavigation/ChildMenu.vue";
import IconChevron from "~/modules/shared/components/icons/IconChevron.vue";
import type { HeaderNavigationItem } from "~/modules/shared/containers/header/headerNavigation.types";
import { useGlobalStore } from "~/modules/shared/stores/global";

const localePath = useLocalePath();
const globalStore = useGlobalStore();
const { headerMenu } = storeToRefs(globalStore);

const activeMobileChildMenu: Ref<number | null> = ref(null);

const menuItems: ComputedRef<HeaderNavigationItem[]> = computed(() => {
  if (!headerMenu.value?.attributes) return [] as HeaderNavigationItem[];

  return headerMenu.value?.attributes.items.map(
    ({ id, child_menu, page, title, url }) => {
      let targetUrl = url || null;

      if (page.data) {
        targetUrl = `/${page.data?.attributes.slug}`;
      }

      return {
        childMenu: child_menu,
        id,
        title,
        url: targetUrl,
      };
    },
  );
});

const handleMobileChildMenuButtonClick = (menuId: number) => {
  if (activeMobileChildMenu.value === menuId) {
    activeMobileChildMenu.value = null;
    return;
  }

  activeMobileChildMenu.value = menuId;
};
</script>

<style lang="scss" scoped>
.items__list {
  @apply flex flex-col md:flex-row md:pl-8 xl:px-8 2xl:px-14 text-body-xs md:text-body-xxs;

  .list__item {
    @apply relative flex flex-wrap justify-between md:mr-8 xl:mr-16 py-4 text-center;

    &:last-child {
      @apply mr-0;
    }

    &:hover {
      .child-menu__container {
        @screen md {
          @apply opacity-100 visible pointer-events-auto;
        }
      }
    }
  }
}

.mobile-child-menu-button {
  @apply md:hidden flex items-center px-5 border-l-2 border-primary-100;

  &--active {
    .icon {
      transform: rotate(180deg);
    }
  }

  .icon {
    transition: 0.4s;
  }
}

.child-menu__container {
  @media screen and (max-width: theme("screens.md")) {
    @apply hidden text-left;

    &--active {
      @apply flex;
    }
  }

  @screen md {
    @apply opacity-0 invisible pointer-events-none;
    transition: 0.2s;
  }
}
</style>
